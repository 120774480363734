import './index.scss';
import { useEffect, useRef, useState } from 'react';
import * as utils from '../utils';
import IssuesListComponent from '../issue-list-component';
import TranscriptComponent from '../transcript-component/transcript-component';
import MediaPlayer from './media-player';
import { ReviewIssue } from '../../../resources/submission/submission-types';
import ViolationRuleModal from '../violation-rule-modal/violation-rule-modal-component';
import { FiAlertTriangle } from 'react-icons/fi';
import AlertComponent from '../../../components/ui/alert';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import * as submissionReducer from '../../../redux/reducers/submissionReducer';
import * as transcriptReducer from '../../../redux/reducers/transcriptReducer';
import * as ruleViolationModalReducer from '../../../redux/reducers/ruleViolationModalReducer';
import { sortByTimeStartDESC } from '../../../helpers/submission-reviews-helper';
import { SubmissionDetailsDTO } from '../../../interface/submission-details-dto';
import { ReviewIssueViolationRule } from '../../../interface/review-issue-violation-rule';
import { SubmissionIssueDTO } from '../../../interface/submission-issue-dto';
import { ISegment } from '../../../interface/segment';
import { CustomOptionType } from '../transcript-component/custom-language-select/custom-language-select';

export default function TranscriptTab() {
  const [transcript, setTranscript] = useState('');
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [playerState, setPlayerState] = useState<boolean>(true);
  const [playbackRate, setPlaybackRate] = useState<number>(1);
  const [syncMode, setSyncMode] = useState<string>('');

  const videoRef = useRef<HTMLVideoElement>(null);

  const {
    reviewAlertActiveTimeout,
    isReviewAlertActive,
    isMediaPlayerExtended,
    isSelectingContext,
    isTranscriptExtended,
    submissionReviews,
    ruleViolationsInfoMessage,
  } = useAppSelector(state => state.submission);
  const currentRecord: SubmissionDetailsDTO = useAppSelector(state => state.submission.currentRecord);
  const { transcriptTabErrorMessage } = useAppSelector(state => state.transcript);
  const selectedLanguage: CustomOptionType = useAppSelector(state => state.transcript.selectedLanguage);
  const dispatch = useAppDispatch();

  /**
   * Event triggered when the user changes the submission version. Update rule violations, etc.
   */
  useEffect(() => {
    if (currentRecord) {
      setTranscript('');
      buildTranscript(currentRecord);
    }
  }, [currentRecord, submissionReviews]);

  useEffect(() => {
    const observeSpans = () => {
      const spans = document.querySelectorAll('.issue-text-match');
      spans.forEach(span => {
        span.addEventListener('click', handleSpanClick);
      });
    };

    const observer = new MutationObserver((mutationsList, observer) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          observeSpans();
        }
      }
    });

    const config = { childList: true, subtree: true };
    observer.observe(document.body, config);

    // Clean up
    return () => {
      observer.disconnect();
      const spans = document.querySelectorAll('.issue-text-match');
      spans.forEach(span => {
        span.removeEventListener('click', handleSpanClick);
      });
    };
  }, [transcript]);

  function skipToTime(timeInSeconds: number) {
    if (videoRef.current) {
      videoRef.current.currentTime = timeInSeconds;
    }
  }

  function updateReviewIssueViolationRules(
    reviewIssueViolationRules: ReviewIssueViolationRule[],
    issueToBeUpdated: ReviewIssueViolationRule,
  ) {
    const reviewIssueViolationRuleIndex: number = reviewIssueViolationRules.findIndex(
      rivr => rivr.id === issueToBeUpdated.id,
    );
    const updatedReviewIssueViolationRules = [...reviewIssueViolationRules];

    if (reviewIssueViolationRuleIndex !== -1) {
      updatedReviewIssueViolationRules[reviewIssueViolationRuleIndex] = issueToBeUpdated;

      return updatedReviewIssueViolationRules;
    }

    return reviewIssueViolationRules;
  }

  /**
   * Saves the current state of the player when the user expands the transcript in order to keep the same state.
   * @param playerState
   */
  function updatePlayerState(playerState: boolean) {
    setPlayerState(playerState);
  }

  /**
   * Saves the current playback rate of the player when the player expands the transcript in order to keep the same rate.
   * @param rate
   */
  function updatePlaybackRate(rate: number) {
    setPlaybackRate(rate);
  }

  /**
   * Saves the current time of the player when expanding the transcript in order to keep the same time.
   * @param playerCurrentTime
   */
  function updatePlayerCurrentTime(playerCurrentTime: number) {
    setCurrentTime(playerCurrentTime);
  }

  /**
   * Saves the current sync state of the player.
   * @param event
   */
  function handleSyncChange(mode: string) {
    localStorage.setItem('sync-mode', mode);
    setSyncMode(mode);
  }

  /**
   * Build the transcript.
   */
  function buildTranscript(submission: SubmissionDetailsDTO | null) {
    if (!submission) {
      console.error(`The submission on buildTranscript function is: ${submission}`);
      return;
    }
    if (!submission.segments) {
      return;
    }

    const allTimes: number[] = [];

    let segments = submission.segments;

    if (typeof segments === 'string') {
      segments = JSON.parse(segments);
    }

    const merge = utils.mergeSegmentsAndTranscript(segments || []);
    const sentencesArray = merge.mergedSegments.map((segment: any) => segment.text);
    let buildedTranscript = merge.transcript;
    const timestampString = utils.stringTimestamp(merge.mergedSegments);
    dispatch(transcriptReducer.setMergedTranscript(merge.transcript));
    dispatch(transcriptReducer.setMergedSegments(merge.mergedSegments));

    var issues = sortAndUpdateIssuesTimes(merge.transcript, timestampString);
    var segmentsWithIssuesIds = attachIssuesIdstoSegments(merge.mergedSegments, issues);

    // Filling all segments spans.
    buildedTranscript = FillRegularSpans(segmentsWithIssuesIds, buildedTranscript);
    buildedTranscript = buildedTranscript.replaceAll('___', '');

    const nativeLanguage = currentRecord.languageName.toLowerCase();

    if (
      nativeLanguage.toLocaleLowerCase() === 'english' ||
      (nativeLanguage.toLocaleLowerCase() !== 'english' &&
        selectedLanguage.label &&
        selectedLanguage.label.toLocaleLowerCase() !== 'english')
    ) {
      // Build rule violations.
      buildedTranscript = BuildRuleViolation(
        issues,
        buildedTranscript,
        merge.transcript,
        timestampString,
        merge.mergedSegments,
      );
    }

    // Building the keyword list.
    buildedTranscript = BuildKeywordList(
      submission,
      sentencesArray,
      allTimes,
      buildedTranscript,
      timestampString,
      merge.transcript,
      merge.mergedSegments,
    );

    buildedTranscript = buildedTranscript.replaceAll('__', '');

    setTranscript(buildedTranscript);
  }

  /**
   * Attach issuesIds to segments from ReviewSheet issues by timeStart and timeEnd
   * @returns Array of Segments with issueIds filled
   */
  function attachIssuesIdstoSegments(segments: ISegment[], issues: ReviewIssue[]) {
    return segments.map((s: ISegment) => {
      return {
        ...s,
        issuesIds: issues.filter(i => s.start >= i.timeStart! && s.start < i.timeEnd!).map(i => i.id),
      } as ISegment;
    });
  }

  /**
   * Sort issues by timeStart and update both timeStart and timeEnd for each issue
   * @returns
   */
  function sortAndUpdateIssuesTimes(originalTranscript: string, timestampString: string) {
    const clonedAndSortedSubmissionReviews = [...submissionReviews].sort(sortByTimeStartDESC);

    let result: ReviewIssue[] = [];

    // Loop through each rule violation to build the issues and transcript.
    clonedAndSortedSubmissionReviews?.forEach((submissionReview: SubmissionIssueDTO) => {
      let shouldAddIdOnly = false;
      // When all userReviewStatus from issue are false, we flag it to only add the id property.
      shouldAddIdOnly = submissionReview.reviewIssueViolationRule.every(
        (issue: ReviewIssueViolationRule) => issue.userReviewStatus === false,
      );

      // all the ones created by the user already have the time.
      let timeStart = submissionReview.reviewIssue.timeStart!; // CONTEXT time start.
      let timeEnd = submissionReview.reviewIssue.timeEnd!;

      // Try to find the segment for ML cases that does not have time filled.
      if (timeStart == null) {
        const result = utils.findIssueStartTime(
          timestampString,
          originalTranscript,
          submissionReview.reviewIssue.issueContext || submissionReview.reviewIssue.issueContent,
        );
        if (result) {
          const { startTime, endTime } = result;
          timeStart = startTime;
          timeEnd = endTime;
        }
      }

      let issue: ReviewIssue = {
        ...submissionReview.reviewIssue,
        timeStart: Math.floor(timeStart),
        timeEnd: Math.floor(timeEnd),
        shouldAddIdOnly: shouldAddIdOnly,
      };
      result.push(issue);
    });
    return result;
  }

  function BuildRuleViolation(
    issues: ReviewIssue[],
    buildedTranscript: string,
    originalTranscript: string,
    timestampString: string,
    joinedSegments: ISegment[],
  ) {
    // Loop through each rule violation to build the issues and transcript.
    issues?.forEach((reviewIssue: ReviewIssue, index: number) => {
      const ruleViolationId = `ruleViolation_${reviewIssue.timeStart}_${index}`;

      if (reviewIssue.shouldAddIdOnly) return;

      buildedTranscript = utils.addSpanToText(
        buildedTranscript.replaceAll('\xA0', ' '),
        String(reviewIssue.timeStart),
        String(reviewIssue.timeEnd),
        reviewIssue.issueContent.replaceAll('\xA0', ' '),
        reviewIssue.issueContext.replaceAll('\xA0', ' '),
        'r__e__d',
        ruleViolationId,
        'highlight',
        reviewIssue.issues,
        originalTranscript.replaceAll('\xA0', ' '),
        timestampString,
        joinedSegments,
        [reviewIssue.id as number],
      );
    });

    return buildedTranscript;
  }

  function FillRegularSpans(joinedSegments: ISegment[], buildedTranscript: string) {
    for (let index = 0; index < joinedSegments.length; index++) {
      const segment = joinedSegments[index];
      buildedTranscript = utils.addSpanToText(
        buildedTranscript,
        segment.start.toString(),
        segment.end.toString(),
        segment.text,
        null,
        'n__o__n__e',
        '',
        'segment',
        [],
        undefined,
        undefined,
        undefined,
        segment.issuesIds,
      );
    }
    return buildedTranscript;
  }

  function BuildKeywordList(
    file: SubmissionDetailsDTO,
    sentencesArray: any[],
    allTimes: number[],
    buildedTranscript: string,
    timestampString: string,
    originalTranscript: string,
    joinedSegments: ISegment[],
  ) {
    if (file.eachKeywordUsedCount && file.eachKeywordUsedCount[0] && file.eachKeywordUsedCount[0] !== '{}') {
      for (const [keyword, count] of Object.entries(JSON.parse(file.eachKeywordUsedCount[0].replace(/'/g, '"')))) {
        for (let instanceNumber = 1; instanceNumber <= Number(count); instanceNumber++) {
          const keySentenceIndex = utils.findNthContainedStringIndex(sentencesArray, keyword, instanceNumber);
          if (keySentenceIndex > -1) {
            const startTime = Math.floor(joinedSegments[keySentenceIndex]?.start);
            const text: string = joinedSegments[keySentenceIndex]?.text;

            if (!startTime) continue;

            const wasAlreadyTheTime = allTimes.includes(startTime);

            if (!wasAlreadyTheTime) allTimes.push(startTime);

            const keywordId = `${String(startTime)}_${instanceNumber}_${Math.random()}`;

            buildedTranscript = utils.addSpanToText(
              buildedTranscript,
              String(startTime),
              String(startTime),
              text, // content
              null, // context
              'b__l__a__c__k',
              keywordId,
              'keyword',
              [], // issue list
              originalTranscript,
              timestampString,
              joinedSegments,
            );
          }
        }
      }
    }
    return buildedTranscript;
  }

  // Click event on the transcript text.
  function handleSpanClick(event: any) {
    event.stopPropagation();
    dispatch(transcriptReducer.setByPassSync(true));
    let target = event.target as HTMLSpanElement;

    // Check if the clicked span is inside a RV span.
    if (
      !target.className.includes('red') &&
      target instanceof HTMLElement &&
      target.parentElement &&
      target.parentElement.className.includes('red')
    ) {
      target = target.parentElement;
    }
    //utils.removePreviousBold();
    utils.removePreviousSelected();

    if (target.dataset.div !== undefined) {
      const clickedSpan = document.querySelector(`#${target.id}`) as HTMLSpanElement;

      if (clickedSpan) {
        utils.updatePlayerCurrentTime(clickedSpan.dataset.start!);

        if (!clickedSpan.className.includes('selected')) {
          clickedSpan.className += ' selected';
        }
      }
    } else if (target.dataset.start) {
      skipToTime(Number(target.dataset.start));
    }
  }

  function contextSelectCancel() {
    dispatch(ruleViolationModalReducer.setMode('issue'));
    dispatch(submissionReducer.setIsSelectingContext(false));
    dispatch(ruleViolationModalReducer.setShowModal(true));
  }

  function closeTranscriptErrorAlert() {
    dispatch(transcriptReducer.setTranscriptTabErrorMessage(''));
  }

  function closeRuleViolationInfoAlert() {
    clearInfoAlert();
  }

  function clearInfoAlert() {
    dispatch(submissionReducer.setRuleViolationsInfoMessage(''));
  }

  function closeReviewAlert() {
    dispatch(submissionReducer.setIsReviewAlertActive(false));
    clearTimeout(reviewAlertActiveTimeout);
    dispatch(submissionReducer.setReviewAlertTimeout(undefined));
  }

  return (
    <div className="reviewTab contentCards">
      {isReviewAlertActive && (
        <AlertComponent
          type="danger"
          text="To submit a review you need to agree, disagree, or edit each issue."
          buttonText="X"
          onClick={closeReviewAlert}
        />
      )}
      {transcriptTabErrorMessage && (
        <AlertComponent
          type="danger"
          text={transcriptTabErrorMessage}
          buttonText="X"
          onClick={closeTranscriptErrorAlert}
        />
      )}
      {ruleViolationsInfoMessage && (
        <AlertComponent
          type="info"
          text={ruleViolationsInfoMessage}
          buttonText="X"
          onClick={closeRuleViolationInfoAlert}
        />
      )}
      <div className={isMediaPlayerExtended ? 'grid mediaExtended' : ' grid mediaCollapsed'}>
        <div className={`mediaPlayer ${currentRecord?.mediaType.toLowerCase() === 'video' ? 'video' : 'audio'}`}>
          <MediaPlayer
            videoRef={videoRef}
            currentTime={currentTime}
            playerState={playerState}
            playbackRate={playbackRate}
            syncMode={syncMode}
          />
        </div>

        <div className={`issueComponent ${currentRecord?.mediaType.toLowerCase() === 'video' ? 'video' : 'audio'}`}>
          <IssuesListComponent />
        </div>

        <div
          className={`transcriptComponent ${currentRecord?.mediaType.toLowerCase() === 'video' ? 'video' : 'audio'}`}>
          <TranscriptComponent
            transcript={transcript}
            videoRef={videoRef}
            updatePlayerState={updatePlayerState}
            updatePlaybackRate={updatePlaybackRate}
            updatePlayerCurrentTime={updatePlayerCurrentTime}
            onSyncChange={handleSyncChange}
          />

          {isSelectingContext && !isTranscriptExtended && (
            <div className="contextToast">
              <div>
                <FiAlertTriangle />
                <p>Select Context to continue</p>
              </div>
              <p className="cancel" onClick={contextSelectCancel}>
                Cancel
              </p>
            </div>
          )}
        </div>

        <ViolationRuleModal />

        {isSelectingContext && <div className="overlay"></div>}
      </div>
    </div>
  );
}
