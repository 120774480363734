import React, { useCallback, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import NavBarComponent from '../navbar';
import SideNavComponent from '../side-nav';
import './index.scss';
import authService from '../../../resources/auth/auth.service';
import { useAdminStatus } from '../../../hooks/permissions-hook';

const AuthenticatedLayout: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const location = useLocation();
  const isAdmin = useAdminStatus();
  const toggleMenu = useCallback(() => {
    setOpen(!open);
  }, [setOpen, open]);

  const isInSubmissionScreen = location.pathname === '/submission';

  function signOut() {
    authService.clearSession();
    window.location.reload();
  }

  return (
    <>
      <NavBarComponent />
      <div className="aside-main">
        {isAdmin && !isInSubmissionScreen && (
          <SideNavComponent open={open} toggleMenu={toggleMenu} onLogout={signOut} />
        )}
        <main id="main" onClick={open ? toggleMenu : undefined}>
          <Outlet />
        </main>
      </div>
    </>
  );
};

export default AuthenticatedLayout;
