import { useCallback, useState } from 'react';
import ViolationRuleContext from './violation-rule-context';
import { ViolationRule } from './violation-rule-types';
import { ExternalData } from '../../@types/external-api';
import {
  makeExternalCallErrorData,
  makeExternalDataInitialData,
  makeExternalDataSuccessData,
} from '../../helpers/external-data';
import violationRuleService from './violation-rule.service';

interface ViolationRuleProviderProps {
  children: React.ReactNode;
}

const ViolationRuleProvider: React.FC<ViolationRuleProviderProps> = ({ children }) => {
  const [violationRules, setViolationRules] = useState<ExternalData<ViolationRule[]>>(makeExternalDataInitialData());
  const [isRequestingViolationRules, setRequestingViolationRules] = useState<boolean>(false);

  const fetchAllViolationRules = useCallback(() => {
    setRequestingViolationRules(true);
    setViolationRules(makeExternalDataInitialData());
    try {
      const { promise, abort } = violationRuleService.getAllViolationRules();
      promise
        .then((data: any) => setViolationRules(makeExternalDataSuccessData(data)))
        .finally(() => {
          setRequestingViolationRules(false);
        });
      return abort;
    } catch (err: any) {
      setViolationRules(makeExternalCallErrorData(err));
    }
  }, []);

  const fetchEnabledViolationRules = useCallback(() => {
    setRequestingViolationRules(true);
    setViolationRules(makeExternalDataInitialData());
    try {
      const { promise, abort } = violationRuleService.getEnabledViolationRules();
      promise
        .then((data: any) => setViolationRules(makeExternalDataSuccessData(data)))
        .finally(() => {
          setRequestingViolationRules(false);
        });
      return abort;
    } catch (err: any) {
      setViolationRules(makeExternalCallErrorData(err));
    }
  }, []);

  return (
    <ViolationRuleContext.Provider
      value={{
        violationRules,
        fetchAllViolationRules,
        fetchEnabledViolationRules,
        isRequestingViolationRules,
      }}>
      {children}
    </ViolationRuleContext.Provider>
  );
};

export default ViolationRuleProvider;
