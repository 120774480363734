import './index.scss';
import { LogoLoading, Select } from '@amway/react-components';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getColour } from './utils';
import { Tooltip } from 'react-tooltip';
import { Container, Card, Button } from 'react-bootstrap';
import { InputLabel } from '@mui/material';
import AnalystDecision from './analyst-decision';
import SummaryTab from './summary-tab';
import TranscriptTab from './transcript-tab';
import ReviewTab from './review-tab';
import ReviewHistoryTab from './review-history-tab';
import HistoryTab from './history-tab';
import IssuesTab from './issues-tab';
import AttachmentTab from './attachment-tab';
import { IoMdAttach } from 'react-icons/io';
import { MdOutlineCategory } from 'react-icons/md';
import { capitalizeFirstLetter } from '../../utils/string-utils';
import { formatSourceDate } from '../dashboard/utils';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import * as submissionReducer from '../../redux/reducers/submissionReducer';
import * as transcriptReducer from '../../redux/reducers/transcriptReducer';
import { Tab } from '../../interface/tab';
import { Review } from '../../interface/review';
import { hasTheReviewBeenSent } from '../../helpers/reviews-helper';
import submissionService from '../../resources/submission/submission.service';
import { SubmissionDetailsDTO } from '../../interface/submission-details-dto';
import { ReviewIssueViolationRule } from '../../interface/review-issue-violation-rule';
import { SubmissionIssueDTO } from '../../interface/submission-issue-dto';
import { SubmissionVersionsDTO } from '../../interface/submission-versions';
import { isAudioOrVideoMediaType, isSubmissionProcessing, hasSubmissionError } from '../../helpers/submission-helper';
import NotesTab from './notes-tab';
import useViolationRule from '../../resources/violation-rule/violation-rule-hook';
import { ViolationRule } from '../../resources/violation-rule/violation-rule-types';
import { addBreaks, limitText } from '../../helpers/string-helper';
import AlertMessages from '../../resources/messages/alerts.json';
import EditSubmissionTab from './edit-submission-tab';
import EditReviewTemplateTab from './edit-review-template-tab';
import CategoriesTab from './categories-tab';
import { useAdminStatus } from '../../hooks/permissions-hook';

export default function SubmissionComponent() {
  const [selectValue, setSelectValue] = useState('');
  const [tabs, setTabs] = useState<Array<Tab>>([]);
  const [showAttachmentTab, setShowAttachmentTab] = useState<boolean>(false);
  const [showCategoriesTab, setShowCategoriesTab] = useState<boolean>(false);
  const { violationRules, fetchAllViolationRules } = useViolationRule();
  const initialized = useRef(false);
  const dispatch = useAppDispatch();
  const { submissionReviews, submissionDetails, currentSubmissionIndex } = useAppSelector(state => state.submission);
  const currentTab: Tab = useAppSelector(state => state.submission.currentTab);
  const currentRecord: SubmissionDetailsDTO = useAppSelector(state => state.submission.currentRecord);
  const reviews: Review[] = useAppSelector(state => state.submission.reviews);
  const transcriptState: transcriptReducer.TranscriptState = useAppSelector(state => state.transcript);
  const revSheetHistTabMustBeDisplayed = reviews.some(review => hasTheReviewBeenSent(review));
  const videoRef = useRef<HTMLVideoElement>(null);
  const queryString = window.location.search; // Get the query string from the URL
  const searchParams = new URLSearchParams(queryString); // Create a URLSearchParams object to parse the query string
  const strApplyCode = searchParams.get('applyCode'); // Get the value of the "applyCode" parameter
  const applyCode = strApplyCode !== null ? Number.parseInt(strApplyCode, 10) : 0;
  const isAdmin = useAdminStatus();
  const titleLimit = 100;

  const records = useMemo(() => {
    if (!submissionDetails) return [];
    return submissionDetails?.submissions;
  }, [submissionDetails]);

  const selectOptions = useMemo(() => {
    if (!submissionDetails) return [];
    return submissionDetails?.submissions.map((submission: SubmissionDetailsDTO) => ({
      name: formatSourceDate(submission.sourceDate) + ' - ' + submission.sourceLocation.split('/').reverse()[0],
      value: submission.sourceLocation,
    }));
  }, [submissionDetails]);

  const setSelectedFile = useCallback((submission: SubmissionDetailsDTO) => {
    dispatch(submissionReducer.setCurrentRecord(submission));
    videoRef.current?.load();
  }, []);

  useEffect(() => {
    if (submissionDetails && currentSubmissionIndex === -1) {
      dispatch(submissionReducer.setCurrentSubmissionIndex(0));
    }
  }, [submissionDetails]);

  useEffect(() => {
    if (!initialized.current) {
      // Avoiding duplicated calls on page start
      initialized.current = true;
      fetchSubmission(applyCode);
    }
  }, []);

  useEffect(() => {
    if (submissionDetails) {
      setSelectValue(submissionDetails?.submissions[currentSubmissionIndex].sourceLocation);
      setSelectedFile(submissionDetails?.submissions[currentSubmissionIndex]);
      dispatch(submissionReducer.setSubmissionReviews(submissionDetails?.submissionsReviews[currentSubmissionIndex]));
      dispatch(submissionReducer.setReviews(submissionDetails?.reviews[currentSubmissionIndex]));
    }
  }, [currentSubmissionIndex]);

  useEffect(() => {
    setTabs([
      { id: 0, name: 'Summary', show: true },
      { id: 1, name: 'Transcript', show: shouldDisplayTranscriptTab() },
      { id: 2, name: 'Issues', show: shouldDisplayFileReviewTab() },
      { id: 3, name: 'Review', show: shouldDisplayReviewTab() },
      { id: 4, name: 'History', show: true },
      { id: 5, name: 'Review History', show: revSheetHistTabMustBeDisplayed },
      { id: 6, name: 'Notes', show: true },
      { id: 7, name: 'Edit Submission', show: isAdmin },
      { id: 8, name: 'Edit Review Template', show: isAdmin },
    ]);
  }, [currentRecord]);

  // Load violation rules just once
  useEffect(() => {
    if (!violationRules.data) {
      fetchAllViolationRules();
    }
  }, []);

  // Threat the violation rules to be a nested object.
  useEffect(() => {
    if (violationRules.data) {
      // Create a deep copy of violationRules.data to avoid mutating the original objects.
      const deepCopyData = JSON.parse(JSON.stringify(violationRules.data));
      const nestedViolationRules: ViolationRule[] = [];
      const ruleMap = new Map();

      // Initialize ruleMap with deep copied data.
      deepCopyData.forEach((rule: ViolationRule) => {
        rule.children = [];
        ruleMap.set(rule.id, rule);
      });

      // Populate children references.
      deepCopyData.forEach((rule: ViolationRule) => {
        if (rule.parentId === null) {
          nestedViolationRules.push(rule);
        } else {
          const parentRule = ruleMap.get(rule.parentId);
          if (parentRule) {
            parentRule.children.push(rule);
          }
        }
      });

      dispatch(transcriptReducer.setViolationRules(deepCopyData));
      dispatch(transcriptReducer.setNestedViolationRules(nestedViolationRules));
    }
  }, [violationRules.data]);

  useEffect(() => {
    if (transcriptState.isEditingTranscript && currentTab.name === 'Review') {
      const transcriptTab = tabs.find(tab => tab.name === 'Transcript') as Tab;

      handleInfoMessage(AlertMessages.EDITING_TRANSCRIPT);
      dispatch(submissionReducer.changeCurrentTab(transcriptTab));
    } else if (!transcriptState.isEditingTranscript) {
      clearInfoAlert();
    }
  }, [currentTab]);

  function handleInfoMessage(message: string) {
    dispatch(submissionReducer.setRuleViolationsInfoMessage(message));
  }

  function clearInfoAlert() {
    dispatch(submissionReducer.setRuleViolationsInfoMessage(''));
  }

  function shouldDisplayTranscriptTab() {
    return !(
      !isAudioOrVideoMediaType(currentRecord.sourceLocation) ||
      isSubmissionProcessing(currentRecord) ||
      hasSubmissionError(currentRecord)
    );
  }

  function shouldDisplayReviewTab() {
    return !(isSubmissionProcessing(currentRecord) || hasSubmissionError(currentRecord));
  }

  function shouldDisplayFileReviewTab() {
    return !isAudioOrVideoMediaType(currentRecord.sourceLocation);
  }

  function fetchSubmission(applyCode: number) {
    submissionService
      .getSubmission(applyCode)
      .promise.then((submissionDetails: SubmissionVersionsDTO) => {
        dispatch(submissionReducer.setSubmissionDetails(submissionDetails));
      })
      .catch((error: Error) => {
        console.error(error);
      });
  }

  /**
   * Event triggered when the user changes submission version.
   * @param event
   */
  function handleVersionChange(event: any) {
    let selectedValue = event.target ? event.target.value : event;

    // Find the index of the selected item in the records array
    const selectedIndex = records.findIndex(
      (submission: SubmissionDetailsDTO) => submission.sourceLocation === selectedValue,
    );

    dispatch(submissionReducer.setCurrentSubmissionIndex(selectedIndex));

    const summaryTab = tabs.find(tab => tab.name === 'Summary') as Tab;
    dispatch(submissionReducer.changeCurrentTab(summaryTab));

    // If the selected item is found, set the selected file and submission reviews
    if (selectedIndex !== -1) {
      setSelectValue(selectedValue);
      setSelectedFile(records[selectedIndex]);
      dispatch(submissionReducer.setSubmissionReviews(submissionDetails?.submissionsReviews[selectedIndex] || []));
      dispatch(submissionReducer.setReviews(submissionDetails?.reviews[selectedIndex]));
    }
  }

  function canSubmitReview() {
    return submissionReviews?.every((submissionReview: SubmissionIssueDTO) => {
      const userReviewStatus = submissionReview.reviewIssueViolationRule.every(
        (issue: ReviewIssueViolationRule) => issue.userReviewStatus !== null,
      );

      return userReviewStatus || !isAudioOrVideoMediaType(currentRecord.sourceLocation);
    });
  }

  function handleReviewTab() {
    if (canSubmitReview()) {
      const reviewTab = tabs.find(tab => tab.name === 'Review') as Tab;
      dispatch(submissionReducer.changeCurrentTab(reviewTab));
    } else {
      const transcriptTab = tabs.find(tab => tab.name === 'Transcript') as Tab;
      dispatch(submissionReducer.changeCurrentTab(transcriptTab));
      dispatch(submissionReducer.setIsReviewAlertActive(true));
      const reviewAlertTimeout = setTimeout(() => {
        dispatch(submissionReducer.setIsReviewAlertActive(false));
      }, 120000);
      dispatch(submissionReducer.setReviewAlertTimeout(reviewAlertTimeout));
    }
  }

  function activeTab(tabId: number) {
    return currentTab.id === tabId;
  }

  function handleTabClick(tab: Tab) {
    if (tab.name === 'Review') {
      handleReviewTab();
    } else {
      dispatch(submissionReducer.changeCurrentTab(tab));
    }
  }

  return (
    <Container>
      {selectValue !== '' ? (
        <Card>
          <Card.Body>
            <div className="submission-title-container">
              <h4
                className="submission-title"
                data-tooltip-id="submission-tooltip"
                data-tooltip-html={
                  currentRecord.englishTitle.length > titleLimit ? addBreaks(currentRecord.englishTitle) : ''
                }>
                "
                {currentRecord.englishTitle.length > titleLimit
                  ? limitText(currentRecord.englishTitle, titleLimit)
                  : `${currentRecord.englishTitle}"`}
              </h4>
              <div className="submission-version-container">
                <InputLabel id="selectVersion" className="submission-select-version-label">
                  Version:
                </InputLabel>
                <Select
                  id="selectVersion"
                  className="select-version"
                  onChange={e => handleVersionChange(e)}
                  options={selectOptions}
                  value={selectValue}
                  placeholder=""
                  required
                  size="sm"
                  variant="secondary"
                />
              </div>
            </div>

            <div className="submission-header-container">
              <div className="line-before-tab"></div>
              <div className="tab-buttons">
                {tabs.map((tab: Tab, index: number) => (
                  <Button
                    className={`tab-button ${activeTab(index) ? 'active' : ''}`}
                    hidden={!tab.show}
                    onClick={() => handleTabClick(tab)}
                    key={index}>
                    <div>{tab.name}</div>
                  </Button>
                ))}
              </div>

              {currentRecord && (
                <div className="submission-header-content">
                  <div className="decision">
                    <div className="decision-details">
                      <span className="decision-title">
                        {currentRecord.modelDecision !== null ? 'ML Recomendation:' : ''}
                      </span>
                      <span className={'decision-value ' + getColour(currentRecord.modelDecision)}>
                        {currentRecord.modelDecision !== null
                          ? capitalizeFirstLetter(currentRecord.modelDecision.toLowerCase())
                          : ''}
                      </span>

                      <span className="decision-title">{currentRecord.decisionReason ? 'Reason:' : ''}</span>
                      <span className="decision-value">
                        {currentRecord.decisionReason ? capitalizeFirstLetter(currentRecord.decisionReason) : ''}
                      </span>
                    </div>
                  </div>
                  <div className="right-side-items">
                    <Button
                      className={`right-side-items-button ${showCategoriesTab ? 'right-side-items-button-active' : ''}`}
                      onClick={() => {
                        setShowCategoriesTab(!showCategoriesTab);
                        setShowAttachmentTab(false);
                      }}
                      data-tooltip-id="submission-tooltip"
                      data-tooltip-content="Content Categories">
                      <MdOutlineCategory size={20} />
                    </Button>
                    <CategoriesTab open={showCategoriesTab} onClose={() => setShowCategoriesTab(false)} />
                    <Button
                      className={`right-side-items-button ${showAttachmentTab ? 'right-side-items-button-active' : ''}`}
                      onClick={() => {
                        setShowAttachmentTab(!showAttachmentTab);
                        setShowCategoriesTab(false);
                      }}
                      data-tooltip-id="submission-tooltip"
                      data-tooltip-content="Attachments">
                      <IoMdAttach size={20} />
                    </Button>
                    <AttachmentTab
                      isOpened={showAttachmentTab}
                      onClose={() => setShowAttachmentTab(false)}
                      onCancel={() => setShowAttachmentTab(false)}
                    />
                    <AnalystDecision />
                  </div>
                </div>
              )}
            </div>

            <div className="submission-content">
              {currentTab.name === 'Summary' && <SummaryTab />}

              <div style={{ display: currentTab.name === 'Transcript' ? 'block' : 'none' }}>
                <TranscriptTab />
              </div>

              <div style={{ display: currentTab.name === 'Review' ? 'block' : 'none' }}>
                <ReviewTab />
              </div>

              <div style={{ display: currentTab.name === 'Issues' ? 'block' : 'none' }}>
                <IssuesTab />
              </div>

              {currentTab.name === 'History' && <HistoryTab />}

              {currentTab.name === 'Review History' && <ReviewHistoryTab />}

              {currentTab.name === 'Notes' && <NotesTab />}

              <div style={{ display: currentTab.name === 'Edit Submission' ? 'block' : 'none' }}>
                <EditSubmissionTab />
              </div>

              <div style={{ display: currentTab.name === 'Edit Review Template' ? 'block' : 'none' }}>
                <EditReviewTemplateTab />
              </div>
            </div>
          </Card.Body>
        </Card>
      ) : (
        <div className="loading-screen-submission">
          <LogoLoading />
        </div>
      )}
      <Tooltip id="submission-tooltip" place="top" />
    </Container>
  );
}
