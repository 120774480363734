import { createContext } from 'react';
import { ExternalData } from '../../@types/external-api';
import { ViolationRule } from './violation-rule-types';

export interface ViolationRuleCtxType {
  violationRules: ExternalData<ViolationRule[]>;
  fetchAllViolationRules: () => VoidFunction | undefined;
  fetchEnabledViolationRules: () => VoidFunction | undefined;
  isRequestingViolationRules: boolean;
}

const ViolationRuleContext = createContext<ViolationRuleCtxType>(null as any);

export default ViolationRuleContext;
