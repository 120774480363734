import { Paper } from '@mui/material';
import './index.scss';
import moment from 'moment';
import SelectComponent from '../../components/ui/select-component';
import { IOption } from '../../components/ui/multi-select-dropdown';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { useFetchOptionsData } from '../../hooks/options-data-hook';
import { capitalizeWords } from '../../utils/string-utils';
import { Affiliate } from '../../interface/affiliate';
import { ApprovedProvider } from '../../interface/approved-provider';
import LoaderComponent from '../../components/ui/loader';
import reportsService from '../../resources/reports/reports.service';
import AlertComponent from '../../components/ui/alert';

interface FormState {
  [key: string]: {
    value: string;
    label: string;
  };
}

const defaultSubmissionDetailsFormState = {
  startDate: { value: '', label: '' },
  endDate: { value: '', label: '' },
  affiliate: { value: '', label: '' },
  approvedProvider: { value: '', label: '' },
};

export default function Reports() {
  const [reportFormState, setReportFormState] = useState<FormState>(defaultSubmissionDetailsFormState);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const { affiliates, approvedProviders } = useAppSelector(state => state.options);
  const { fetchOptionsData } = useFetchOptionsData();

  // load all the dropdown options just once
  useEffect(() => {
    fetchOptionsData([
      { request: 'affiliates', data: affiliates },
      { request: 'approvedProviders', data: approvedProviders },
    ]);
  }, []);

  function handleSelectInputChange(inputName: string, option: IOption) {
    setReportFormState(prevState => ({
      ...prevState,
      [inputName]: {
        value: option.value,
        label: option.label,
      },
    }));
  }

  function handleDateInputs(inputName: string, dateP: string) {
    let date = dateP;

    if (moment(date).isAfter(moment())) {
      date = moment().format('YYYY-MM-DD');
    }

    setReportFormState(prevState => ({
      ...prevState,
      [inputName]: {
        value: date,
        label: date,
      },
    }));
  }

  const allAffiliatesOptions = useMemo<IOption[]>(() => {
    if (!affiliates) {
      return [] as IOption[];
    }

    const affiliatesOptions = affiliates.map((affiliate: Affiliate) => ({
      value: affiliate.affiliateCd.toString(),
      label: capitalizeWords(affiliate.affiliateName.toLowerCase()),
    })) as IOption[];

    affiliatesOptions.unshift({ value: '', label: '' });

    return affiliatesOptions;
  }, [affiliates]);

  const allApprovedProviders = useMemo<IOption[]>(() => {
    if (!approvedProviders) {
      return [] as IOption[];
    }

    const approvedProvidersOptions = approvedProviders.map((approvedProvider: ApprovedProvider) => ({
      value: approvedProvider.id.toString(),
      label: approvedProvider.fullName,
    })) as IOption[];

    approvedProvidersOptions.unshift({ value: '', label: '' });

    return approvedProvidersOptions;
  }, [approvedProviders]);

  function downloadReport() {
    setErrorMessage('');

    const startDate = reportFormState.startDate.value;
    const endDate = reportFormState.endDate.value;
    const affiliate = reportFormState.affiliate.value || null;
    const approvedProvider = reportFormState.approvedProvider.value || null;

    if (!startDate || !endDate) {
      setErrorMessage('Start Date and End Date are required');
      return;
    }

    if (moment(startDate).isAfter(endDate)) {
      setErrorMessage('Start Date cannot be later than End Date.');
      return;
    }

    if (moment(endDate).diff(moment(startDate), 'days') > 90) {
      setErrorMessage('The date range cannot exceed 90 days.');
      return;
    }

    setIsDownloading(true);

    reportsService
      .getXlsxReport(startDate, endDate, affiliate, approvedProvider)
      .promise.then((response: any) => {
        downloadFile(response, `submissions_report_${startDate}_${endDate}`);
      })
      .catch((e: Error) => {
        console.error('Error downloading report', e);
        setErrorMessage(`Unknown error:  ${e.message}`);
      })
      .finally(() => {
        setIsDownloading(false);
      });
  }

  function downloadFile(response: any, fileName: string) {
    const blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.xlsx`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  return (
    <div className="reports-container">
      {errorMessage && (
        <AlertComponent text={errorMessage} type="danger" onClick={() => setErrorMessage('')} buttonText="X" />
      )}
      {successMessage && (
        <AlertComponent text={successMessage} type="success" onClick={() => setSuccessMessage('')} buttonText="X" />
      )}
      <Paper className="reports-paper" variant="outlined">
        <div className="cc-paper-title">
          <span className="paperText">Reports</span>
        </div>
        <div className="reports-content">
          <div className="reports-inputs">
            <div className="reports-input-container">
              <label htmlFor="start-date" className="reports-input-label">
                Start Date:{' '}
              </label>
              <input
                className="cc-input"
                type="date"
                id="start-date"
                max={moment().format('YYYY-MM-DD')}
                onChange={e => {
                  handleDateInputs('startDate', e.target.value);
                }}
                value={reportFormState.startDate.label}
              />
            </div>
            <div className="reports-input-container">
              <label htmlFor="end-date" className="reports-input-label">
                End Date:{' '}
              </label>
              <input
                className="cc-input"
                type="date"
                id="end-date"
                max={moment().format('YYYY-MM-DD')}
                onChange={e => {
                  handleDateInputs('endDate', e.target.value);
                }}
                value={reportFormState.endDate.label}
              />
            </div>
            <SelectComponent
              label="Affiliate: "
              name="reports-affiliate"
              onChange={(option: IOption) => handleSelectInputChange('affiliate', option)}
              isDisabled={false}
              options={allAffiliatesOptions}
              selectedOption={reportFormState.affiliate}
              labelDirection="column"
            />
            <SelectComponent
              label="Approved Provider: "
              name="reports-ap"
              onChange={(option: IOption) => handleSelectInputChange('approvedProvider', option)}
              isDisabled={false}
              options={allApprovedProviders}
              selectedOption={reportFormState.approvedProvider}
              labelDirection="column"
            />
          </div>
          <div className="button-container">
            <button
              onMouseDown={e => e.preventDefault()}
              onClick={() => downloadReport()}
              disabled={isDownloading}
              className="cc-confirm-button">
              {isDownloading ? (
                <LoaderComponent styles={{ border: '0.2em solid white', borderTop: '0.2em solid transparent' }} />
              ) : (
                'Download reports'
              )}
            </button>
          </div>
        </div>
      </Paper>
    </div>
  );
}
