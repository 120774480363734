import axios from 'axios';
import { WithAbortFn } from '../../@types/external-api';
import { apiWrapper } from '../../config/api-wrapper';

const REPORTS_ENDPOINT = '/reports';
const CSV_REPORTS_ENDPOINT = `${REPORTS_ENDPOINT}/csv`;

class ReportsService {
  public getSubmissionsCsvReport(
    startDate: string,
    endDate: string,
    affiliate: string | null,
    approvedProvider: string | null,
  ): WithAbortFn<Promise<Response>> {
    const source = axios.CancelToken.source();
    return {
      promise: apiWrapper.api.get(
        `${CSV_REPORTS_ENDPOINT}/submissions?startDate=${startDate}&endDate=${endDate}&affiliate=${affiliate}&approvedProvider=${approvedProvider}`,
        {
          responseType: 'blob',
          cancelToken: source.token,
        },
      ),
      abort: source.cancel,
    };
  }
  public getIssuesCsvReport(
    startDate: string,
    endDate: string,
    affiliate: string | null,
    approvedProvider: string | null,
  ): WithAbortFn<Promise<Response>> {
    const source = axios.CancelToken.source();
    return {
      promise: apiWrapper.api.get(
        `${CSV_REPORTS_ENDPOINT}/issues?startDate=${startDate}&endDate=${endDate}&affiliate=${affiliate}&approvedProvider=${approvedProvider}`,
        {
          responseType: 'blob',
          cancelToken: source.token,
        },
      ),
      abort: source.cancel,
    };
  }
  public getSubmissionHistoryCsvReport(
    startDate: string,
    endDate: string,
    affiliate: string | null,
    approvedProvider: string | null,
  ): WithAbortFn<Promise<Response>> {
    const source = axios.CancelToken.source();
    return {
      promise: apiWrapper.api.get(
        `${CSV_REPORTS_ENDPOINT}/submission-history?startDate=${startDate}&endDate=${endDate}&affiliate=${affiliate}&approvedProvider=${approvedProvider}`,
        {
          responseType: 'blob',
          cancelToken: source.token,
        },
      ),
      abort: source.cancel,
    };
  }
  public getXlsxReport(
    startDate: string,
    endDate: string,
    affiliate: string | null,
    approvedProvider: string | null,
  ): WithAbortFn<Promise<Response>> {
    const source = axios.CancelToken.source();
    return {
      promise: apiWrapper.api.get(
        `${REPORTS_ENDPOINT}/xlsx?startDate=${startDate}&endDate=${endDate}&affiliate=${affiliate}&approvedProvider=${approvedProvider}`,
        {
          responseType: 'blob',
          cancelToken: source.token,
        },
      ),
      abort: source.cancel,
    };
  }
}

const reportsService = new ReportsService();
export default reportsService;
