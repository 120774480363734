import DashboardComponent from '../screens/dashboard/dashboard';
import SubmissionComponent from '../screens/submission/index';
import ErrorCodeManagement from '../screens/error-code-management';
import Reports from '../screens/reports';
import { Features } from './features';

export interface RouteData {
  label: string;
  path: string;
  feature: Features;
  /** If this is undefined, we have an unrestricted route */
  element: JSX.Element;
}

export const authenticatedRoutes: RouteData[] = [
  {
    label: 'Dashboard',
    path: '/dashboard',
    feature: Features.Dashboard,
    element: <DashboardComponent />,
  },
  {
    label: 'Submission',
    path: '/submission',
    feature: Features.Submission,
    element: <SubmissionComponent />,
  },
  {
    label: 'Error Code Management',
    path: '/error-code-management',
    feature: Features.ErrorCodeManagement,
    element: <ErrorCodeManagement />,
  },
  {
    label: 'Reports',
    path: '/reports',
    feature: Features.Reports,
    element: <Reports />,
  },
];
