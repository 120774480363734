import axios from 'axios';
import { WithAbortFn } from '../../@types/external-api';
import { apiWrapper } from '../../config/api-wrapper';
import { ViolationRule } from './violation-rule-types';

const VIOLATION_RULE_ENDPOINT = '/classification-code';

class ViolationRuleService {
  public getAllViolationRules(): WithAbortFn<Promise<ViolationRule[]>> {
    const source = axios.CancelToken.source();
    return {
      promise: apiWrapper.api.get(VIOLATION_RULE_ENDPOINT).then((res: any) => res.data),
      abort: source.cancel,
    };
  }
  public getEnabledViolationRules(): WithAbortFn<Promise<ViolationRule[]>> {
    const source = axios.CancelToken.source();
    return {
      promise: apiWrapper.api.get(`${VIOLATION_RULE_ENDPOINT}/enabled`).then((res: any) => res.data),
      abort: source.cancel,
    };
  }
  public createViolationRule(violationRule: Partial<ViolationRule>): WithAbortFn<Promise<ViolationRule>> {
    const source = axios.CancelToken.source();
    return {
      promise: apiWrapper.api.post(`${VIOLATION_RULE_ENDPOINT}/create`, violationRule).then((res: any) => res.data),
      abort: source.cancel,
    };
  }
  public editViolationRule(violationRule: Partial<ViolationRule>): WithAbortFn<Promise<ViolationRule>> {
    const source = axios.CancelToken.source();
    return {
      promise: apiWrapper.api.post(`${VIOLATION_RULE_ENDPOINT}/update`, violationRule).then((res: any) => res.data),
      abort: source.cancel,
    };
  }
  public enabledViolationRule(violationRuleId: number, enabled: boolean): WithAbortFn<Promise<ViolationRule>> {
    const source = axios.CancelToken.source();
    return {
      promise: apiWrapper.api
        .post(`${VIOLATION_RULE_ENDPOINT}/enabled/${violationRuleId}?enabled=${enabled}`)
        .then((res: any) => res.data),
      abort: source.cancel,
    };
  }
}

const violationRuleService = new ViolationRuleService();
export default violationRuleService;
