import './index.scss';
import { Paper } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useEffect, useMemo, useState, useRef } from 'react';
import LoaderComponent from '../../../components/ui/loader';
import AlertComponent from '../../../components/ui/alert';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { ReviewSheetTemplate } from '../../../interface/review-sheet-template';
import DropdownBtn, { Item } from '../../../components/ui/dropdown-btn';
import { CiCircleMinus, CiEdit } from 'react-icons/ci';
import { MdBlock } from 'react-icons/md';
import { Tooltip } from 'react-tooltip';
import { CgUnblock } from 'react-icons/cg';
import { Modal, Button } from 'react-bootstrap';
import reviewSheetService from '../../../resources/review-sheet/review-sheet.service';
import * as reviewReducer from '../../../redux/reducers/reviewReducer';
import { LogoLoading } from '@amway/react-components';
import { isValidEmail } from '../utils';
import JoditEditor from 'jodit-react';

export default function EditReviewTemplateTab() {
  const [selectedTemplate, setSelectedTemplate] = useState<ReviewSheetTemplate>();
  const templates: ReviewSheetTemplate[] = useAppSelector(state => state.review.templates);
  const [showModal, setShowModal] = useState(false);
  const [showTestModal, setShowTestModal] = useState(false);
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [modalMode, setModalMode] = useState<string>();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [templateName, setTemplateName] = useState<string>();
  const [recipient, setRecipient] = useState<string>();
  const [content, setContent] = useState('');
  const [isValidEmailState, setValidEmailState] = useState<boolean>(true);
  const [addingNew, setAddingNew] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('');

  const editor = useRef(null);

  const dispatch = useAppDispatch();

  const handleTemplateClick = (template: ReviewSheetTemplate | Item | null | undefined) => {
    if (!template) {
      return;
    }

    const templateFound = templates.find(
      (currentTemplate: ReviewSheetTemplate) => currentTemplate.id.toString() === template.id.toString(),
    );

    setSelectedTemplate(templateFound);
  };

  function preHandleTemplateModal(state: string) {
    setModalMode(state);
    setShowModal(true);
    setModalTitle('Edit Confirmation');
  }

  function handleTemplateEnable() {
    setIsRequesting(true);
    reviewSheetService
      .enableTemplate(selectedTemplate!.id)
      .promise.then((res: any) => {
        // Update the template in the templates list immutably
        const updatedTemplates = templates.map(template =>
          template.id === selectedTemplate!.id ? { ...template, disabled: false } : template,
        );

        dispatch(reviewReducer.setTemplates(updatedTemplates));
      })
      .catch((error: any) => {
        console.error('Error enabling template:', error);
      })
      .finally(() => {
        setIsRequesting(false);
        setShowModal(false);
      });
  }

  function handleTemplateDisable() {
    setIsRequesting(true);
    reviewSheetService
      .disableTemplate(selectedTemplate!.id)
      .promise.then((res: any) => {
        // Update the template in the templates list immutably
        const updatedTemplates = templates.map(template =>
          template.id === selectedTemplate!.id ? { ...template, disabled: true } : template,
        );

        dispatch(reviewReducer.setTemplates(updatedTemplates));
      })
      .catch((error: any) => {
        console.error('Error disabling template:', error);
      })
      .finally(() => {
        setIsRequesting(false);
        setShowModal(false);
      });
  }

  function handleTemplateDelete() {
    setIsRequesting(true);
    reviewSheetService
      .deleteTemplate(selectedTemplate!.id)
      .promise.then(() => {
        // Remove the deleted template from the list
        const updatedTemplates = templates.filter(template => template.id !== selectedTemplate!.id);
        dispatch(reviewReducer.setTemplates(updatedTemplates));
        setSelectedTemplate(undefined); // Clear the selection if the deleted template was selected
      })
      .catch(error => {
        console.error('Error deleting template:', error);
      })
      .finally(() => {
        setIsRequesting(false);
        setShowModal(false);
      });
  }

  function handleEditTemplate() {
    setAddingNew(false);
    setTemplateName(selectedTemplate!.name);
    setContent(selectedTemplate!.content);
    setIsEditing(true);
  }

  function handleAddTemplate() {
    setAddingNew(true);
    setSelectedTemplate(undefined);
    setTemplateName('');
    setContent('');
    setIsEditing(true);
  }

  function onBlurJodit(newContent: string) {
    const newContentText = removeHtmlTags(newContent);
    setContent(newContent);
  }

  function removeHtmlTags(input: string): string {
    // Remove HTML tags
    let result = input.replace(/<[^>]*>/g, '');

    // Normalize internal whitespace (replace multiple spaces, newlines with a single space)
    result = result.replace(/\s+/g, ' ').trim();

    return result;
  }

  function saveTemplate() {
    if (!templateName || !content) {
      console.error('Template name and content must be provided.');
      return;
    }

    setShowModal(true);
    setIsRequesting(true);
    setModalTitle('Saving Template');

    reviewSheetService
      .saveTemplate(templateName, content, addingNew, selectedTemplate?.id)
      .promise.then(response => {
        // Set 'candelete' to true for new templates
        if (addingNew) {
          response.candelete = true;
        }

        // Add the new or updated template to the templates list immutably
        const updatedTemplates = addingNew
          ? [...templates, response]
          : templates.map(template => (template.id === response.id ? response : template));

        dispatch(reviewReducer.setTemplates(updatedTemplates));
      })
      .catch(error => {
        console.error('Error saving template:', error);
      })
      .finally(() => {
        setIsEditing(false);
        setShowModal(false);
        setIsRequesting(false);
      });
  }

  /**
   * Event triggered when the user changes the recipient.
   * @param email
   */
  function handleEmailChange(email: string) {
    setValidEmailState(isValidEmail(email));
    setRecipient(email);
  }

  function handleSendTestEmail() {
    if (!recipient || !content) {
      console.error('Recipient and content must be provided.');
      alert('Please provide both recipient and email content.');
      return;
    }

    setIsRequesting(true);

    reviewSheetService
      .sendTestEmail(recipient, content)
      .promise.then(response => {})
      .catch(error => {
        console.error('Error sending test email:', error);
      })
      .finally(() => {
        setShowTestModal(false);
        setIsRequesting(false);
      });
  }

  return (
    <div>
      <Paper className="edit-review-template-tab-container contentCard" variant="outlined">
        <div className="paper-title">
          <span className="paperText">Template Management</span>
        </div>
        {isEditing ? (
          <div>
            <div className="edit-review-template-tab-body-edit">
              <div className="template-name-input-container">
                <label htmlFor="template-name-input">Template Name: </label>
                <input
                  id="template-name-input"
                  name="template"
                  type="text"
                  placeholder="Enter the template name"
                  value={templateName}
                  onChange={e => setTemplateName(e.target.value)}
                />
              </div>
              <div className="placeholders">
                <span>List of possible placeholders:</span>
                <ul>
                  <li>$date</li>
                  <li>$ap_legal_entity_name</li>
                  <li>$ap_contact_name</li>
                  <li>$apply_code</li>
                  <li>$status</li>
                  <li>$title</li>
                  <li>$speakers</li>
                  <li>$signature</li>
                  <li>$rule_violations</li>
                </ul>
              </div>
              <JoditEditor
                ref={editor}
                value={content}
                config={{
                  removeButtons: [
                    'classSpan',
                    'file',
                    'video',
                    'speechRecognize',
                    'copyformat',
                    'symbols',
                    'ai-commands',
                    'ai-assistant',
                    'find',
                    'source',
                    'about',
                  ],
                }}
                onBlur={newContent => onBlurJodit(newContent)} // preferred to use only this option to update the content for performance reasons
                onChange={newContent => {}}
              />
            </div>

            <div className="actionsContainer">
              <div className="rightContent">
                <Button className="cancelButton" onClick={() => setIsEditing(false)}>
                  Cancel
                </Button>
                <Button
                  className="testButton"
                  disabled={templateName == '' || content == ''}
                  onClick={() => setShowTestModal(true)}>
                  Test
                </Button>
                <Button className="nextButton" disabled={templateName == '' || content == ''} onClick={saveTemplate}>
                  Save
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="edit-review-template-tab-body">
            <div className="add-new-container">
              <button className="add-new" onClick={handleAddTemplate}>
                ADD NEW
              </button>
            </div>
            <div className="templateContainer">
              {templates.map((template: any, index: number) => (
                <button
                  className="template"
                  key={index}
                  onClick={() => handleTemplateClick(template)}
                  style={{
                    color: selectedTemplate === template ? '#3478F6' : '#DCDCDC', // Change text color
                    borderColor: selectedTemplate === template ? '#3478F6' : '#DCDCDC', // Change border color
                  }}>
                  <div className="actions" style={{ display: selectedTemplate === template ? 'block' : 'none' }}>
                    <CiEdit
                      data-tooltip-id="icon-tooltip"
                      data-tooltip-content="Edit Template"
                      onClick={handleEditTemplate}
                    />
                    {selectedTemplate?.candelete ? (
                      <CiCircleMinus
                        className="template-delete"
                        data-tooltip-id="icon-tooltip"
                        data-tooltip-content="Delete Template"
                        onClick={() => preHandleTemplateModal('delete')}
                      />
                    ) : null}
                    {selectedTemplate?.disabled ? (
                      <CgUnblock
                        className="template-enable"
                        data-tooltip-id="icon-tooltip"
                        data-tooltip-content="Enable Template"
                        onClick={() => preHandleTemplateModal('enable')}
                      />
                    ) : (
                      <MdBlock
                        data-tooltip-id="icon-tooltip"
                        data-tooltip-content="Disable Template"
                        onClick={() => preHandleTemplateModal('disable')}
                      />
                    )}
                  </div>
                  <IoDocumentTextOutline className={selectedTemplate === template ? 'selected' : ''} />
                  <p className={selectedTemplate === template ? 'selected' : ''}>{template.name}</p>
                </button>
              ))}
            </div>
          </div>
        )}
      </Paper>
      <Modal
        className="confirmationModal"
        size="lg"
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="ConfirmationModal">
        <Modal.Header closeButton>
          <Modal.Title className="modalTitle" id="ConfirmationModal">
            {modalTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modalBody">
          {isRequesting ? <LogoLoading /> : <div>Are you sure you want to {modalMode} the current template?</div>}
        </Modal.Body>
        {isRequesting ? (
          <Modal.Footer></Modal.Footer>
        ) : (
          <Modal.Footer>
            <div className="footerContainer">
              <Button className="button backButton" onClick={() => setShowModal(false)}>
                Cancel
              </Button>
              {modalMode === 'enable' ? (
                <Button className="button submitButton" onClick={handleTemplateEnable}>
                  Enable Template
                </Button>
              ) : modalMode === 'disable' ? (
                <Button className="button submitButton" onClick={handleTemplateDisable}>
                  Disable Template
                </Button>
              ) : (
                <Button className="button submitButton" onClick={handleTemplateDelete}>
                  Delete Template
                </Button>
              )}
            </div>
          </Modal.Footer>
        )}
      </Modal>
      <Modal
        className="testEmailModal"
        size="lg"
        show={showTestModal}
        onHide={() => setShowTestModal(false)}
        aria-labelledby="TestEmailModal">
        <Modal.Header closeButton>
          <Modal.Title className="modalTitle" id="TestEmailModal">
            Test Email
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modalBody">
          <div className="email-input-container">
            <label>Recipient Email</label>
            <input
              type="email"
              placeholder="Enter recipient email"
              value={recipient}
              className={!isValidEmailState ? 'input-error' : ''}
              onChange={e => handleEmailChange(e.target.value)}
            />
          </div>
          {/* Conditionally render an error message */}
          {!isValidEmailState && <div className="error-message">Please enter a valid email address.</div>}
        </Modal.Body>
        {isRequesting ? (
          <Modal.Footer></Modal.Footer>
        ) : (
          <Modal.Footer>
            <Button className="button backButton" onClick={() => setShowTestModal(false)}>
              Cancel
            </Button>
            <Button
              className="button submitButton"
              onClick={handleSendTestEmail}
              disabled={!recipient || !isValidEmailState} // Disable button if input is empty
            >
              Send Test Email
            </Button>
          </Modal.Footer>
        )}
      </Modal>
      <Tooltip id="icon-tooltip" place="top" />
    </div>
  );
}
