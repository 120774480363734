import { Paper } from '@mui/material';
import { BsArrowsAngleContract } from 'react-icons/bs';
import { GrExpand } from 'react-icons/gr';
import { RefObject, useEffect, useState } from 'react';
import { removePreviousBold, removePreviousSelected, formatTime } from '../utils';
import { setIsMediaPlayerExtended } from '../../../redux/reducers/submissionReducer';
import * as transcriptReducer from '../../../redux/reducers/transcriptReducer';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { SubmissionDetailsDTO } from '../../../interface/submission-details-dto';
import { isAudioOrVideoMediaType } from '../../../helpers/submission-helper';

interface MediaPlayerProps {
  videoRef: RefObject<HTMLVideoElement>;
  currentTime: number;
  playerState: boolean;
  playbackRate: number;
  syncMode: string;
}

export default function MediaPlayer({ videoRef, currentTime, playerState, playbackRate, syncMode }: MediaPlayerProps) {
  const dispatch = useAppDispatch();
  const { isMediaPlayerExtended, isTranscriptExtended, isSelectingContext } = useAppSelector(state => state.submission);
  const { filteredIssuesOccurrences, byPassSync, byPassSelection, mergedSegments } = useAppSelector(
    state => state.transcript,
  );
  const [mediaUrl, setMediaUrl] = useState('');
  const currentRecord: SubmissionDetailsDTO | null = useAppSelector(state => state.submission.currentRecord);

  useEffect(() => {
    window.addEventListener('beforeunload', savePlaybackTime);
  }, []);

  useEffect(() => {
    if (currentRecord && currentRecord.singedSourceUrl) {
      setMediaUrl(currentRecord.singedSourceUrl);
    }
  }, [currentRecord]);

  useEffect(() => {
    videoRef.current?.load();
  }, [mediaUrl]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = currentTime; // Update the current time
      videoRef.current.playbackRate = playbackRate; // Update playback rate
      if (playerState) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }

      const submissionId = new URLSearchParams(window.location.search).get('id');
      if (submissionId && videoRef.current && currentTime === 0) {
        const savedTime = getCookie(`audio_${submissionId}`);
        if (savedTime) {
          videoRef.current.currentTime = parseFloat(savedTime);
        }
      }

      // Scroll after half second (waits extend)
      setTimeout(function () {
        const el = document.querySelectorAll(`.issue-text-match.selected`);
        if (el.length) {
          el[0].scrollIntoView({ behavior: 'smooth' });
        }
      }, 500);
    }
  }, [currentTime, playerState, videoRef]);

  function savePlaybackTime() {
    const submissionId = new URLSearchParams(window.location.search).get('id');
    if (submissionId && videoRef.current) {
      setCookie(`audio_${submissionId}`, videoRef.current.currentTime);
    }
  }

  function setCookie(name: string, value: number) {
    document.cookie = name + '=' + (value || '') + '; path=/';
  }

  function getCookie(name: string) {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  /**
   * Binary search to look closest timestamp of audio time
   * @param arr mergedSegments
   * @param time currentTime
   * @returns mergedSegment
   */
  function binarySearch(arr: any, time: Number) {
    if (time === 0) return arr[0];
    let start = 0;
    let end = arr.length - 1;
    //let count = 0;
    while (start <= end) {
      let mid = Math.floor((start + end) / 2);
      //count++;
      let el = arr[mid];
      if (time >= el.start && time <= el.end) {
        return arr[mid];
      }

      if (time < el.start) {
        end = mid - 1;
      } else {
        start = mid + 1;
      }
    }
    return null;
  }

  /**
   * Handle progress change, scroll to transcript.
   * @param event
   */
  function handleProgress(event: any) {
    if (!event.target) return;

    savePlaybackTime();
    const currentTime = Math.round(event.target.currentTime);

    let spanEl = document.querySelector(`#spnKeyWord_${currentTime}`) as HTMLSpanElement;

    if (!spanEl) {
      var closestTime = binarySearch(mergedSegments, currentTime);
      if (closestTime) {
        spanEl = document.querySelector(`#spnKeyWord_${closestTime.start}`) as HTMLSpanElement;
      }
    }

    removePreviousSelected();

    if (!spanEl) return;

    let childrenRed = spanEl.querySelectorAll('.red');
    let issueTimestart = parseInt(spanEl.dataset.start!);
    let boldIssueId = '';

    if (childrenRed && childrenRed.length > 0) {
      var timestamps = Array.from(childrenRed).map(c => parseInt((c as HTMLSpanElement).dataset.start!));
      // If there are more and one issue in the same segment
      // We try to match the hightlight from the issue that starts in the current segment
      // Otherwise we use the first issue
      if (timestamps.filter(t => t === issueTimestart).length === 0) {
        issueTimestart = parseInt((childrenRed[0] as HTMLSpanElement).dataset.start!);
      }
    } else {
      boldIssueId = spanEl.dataset.issues || '';
    }
    if (boldIssueId) {
      boldHighlights(0, boldIssueId);
    } else {
      boldHighlights(issueTimestart, null);
    }
    let issueOccurrence = filteredIssuesOccurrences.filter(
      (issueOccurrence: any) => issueOccurrence.startTime == issueTimestart,
    );

    if (issueOccurrence.length) {
      if (!byPassSelection) {
        dispatch(transcriptReducer.setSelectedIssueOccurrence(issueOccurrence[0].occurrence));
      }

      let issueOccurrenceDiv = document.querySelectorAll(
        `div[id^='ruleViolation_${issueOccurrence[0].startTime}_']`,
      ) as NodeListOf<Element>;

      if (issueOccurrenceDiv && issueOccurrenceDiv.length) {
        scrollIntoDiv(issueOccurrenceDiv);
      } else {
        issueOccurrenceDiv = document.querySelectorAll(`div[id^='${issueOccurrence[0].startTime}_']`);
        scrollIntoDiv(issueOccurrenceDiv);
      }
    }

    if (!spanEl.className.includes('selected')) {
      spanEl.className = spanEl.className + ' selected';
    }

    if (syncMode !== 'off' || byPassSync) {
      if (syncMode === 'mid' || byPassSync) {
        setTimeout(() => {
          spanEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 100);
      } else {
        setTimeout(() => {
          spanEl.scrollIntoView({ behavior: 'smooth' });
        }, 100);
      }
    }

    dispatch(transcriptReducer.setByPassSync(false));
    dispatch(transcriptReducer.setByPassSelection(false));
  }

  function scrollIntoDiv(div: any) {
    if (div && div.length) {
      div[0].scrollIntoView({ block: 'center' });
    }
  }

  /**
   * Bold all highlights based on current player time.
   * @param currentTime
   */
  function boldHighlights(startTime: number, issueId: string | null) {
    const highlightSpanElements =
      issueId == null
        ? document.querySelectorAll(`span.red[data-start="${startTime}"]`)
        : document.querySelectorAll(`span.red[data-issues="${issueId}"]`);

    const alreadyHightlighted =
      issueId == null
        ? document.querySelectorAll(`span.red.bold[data-start]`)
        : document.querySelectorAll(`span.red.bold[data-issues]`);

    if (
      alreadyHightlighted &&
      alreadyHightlighted.length === highlightSpanElements.length &&
      alreadyHightlighted.length > 0 &&
      parseInt((alreadyHightlighted[0] as HTMLSpanElement).dataset.start!) == startTime
    )
      return;
    removePreviousBold();

    if (highlightSpanElements && highlightSpanElements.length) {
      highlightSpanElements.forEach(highlightSpan => {
        if (!highlightSpan.className.includes('bold')) highlightSpan.className += ' bold';
      });
    }
  }

  function onMediaError(event: React.SyntheticEvent) {
    const mediaElement = event.currentTarget as HTMLMediaElement;

    if (mediaElement.error) {
      console.error(mediaElement.error.message);
      dispatch(transcriptReducer.setTranscriptTabErrorMessage(`Error loading media: ${mediaElement.error.message}`));
    } else {
      console.error('Error loading media: Unknown error');
    }
  }

  return isAudioOrVideoMediaType(currentRecord?.sourceLocation || '') ? (
    <div>
      <Paper className="contentCard mediaPlayer " variant="outlined">
        <div className="paper-title videoTitle">
          <span className="paperText">{currentRecord?.mediaType}</span>
          {mergedSegments[0]?.start !== 0 && <span>Transcript starts at {formatTime(mergedSegments[0]?.start)}</span>}
          <div className="functionals">
            <button onClick={() => dispatch(setIsMediaPlayerExtended(!isMediaPlayerExtended))}>
              {isMediaPlayerExtended ? <BsArrowsAngleContract className="expand" /> : <GrExpand className="expand" />}
            </button>
          </div>
        </div>
        {currentRecord?.mediaType.toLowerCase() === 'video' && !isTranscriptExtended ? (
          <video onError={onMediaError} ref={videoRef} controls key="video" onTimeUpdate={handleProgress}>
            {currentRecord && mediaUrl && <source src={mediaUrl} type="video/mp4" />}
          </video>
        ) : (
          <audio
            ref={videoRef}
            onError={onMediaError}
            controls
            key="audio"
            onTimeUpdate={handleProgress}
            className={`${isTranscriptExtended ? 'full-player' : ''} ${
              isSelectingContext && isTranscriptExtended ? 'hide-player' : ''
            }`}>
            {currentRecord && mediaUrl && <source src={mediaUrl} type="audio/mp3" />}
          </audio>
        )}
        {
          //@ts-ignore
          document.getElementById('selectVersion')?.value?.endsWith('.mp3') ? (
            <p className="videoParagraph">mp3</p>
          ) : (
            <p className="videoParagraph"></p>
          )
        }
      </Paper>
    </div>
  ) : (
    <></>
  );
}
