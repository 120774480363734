import { IOption } from '../components/ui/multi-select-dropdown';

interface IFiltersLocalStorage {
  search: string;
  affiliate: string[];
  active: string[];
  status: string[];
  selectedSubmitter: string[];
  selectedUser: string[];
  approvedProvider: string[];
}

const defaultFilters: IFiltersLocalStorage = {
  search: '',
  affiliate: [],
  active: [],
  status: [],
  selectedSubmitter: [],
  selectedUser: [],
  approvedProvider: [],
};

const normalizeFilters = (filters: IFiltersLocalStorage): IFiltersLocalStorage => {
  return {
    search: filters.search || defaultFilters.search,
    affiliate: filters.affiliate || defaultFilters.affiliate,
    active: filters.active || defaultFilters.active,
    status: filters.status || defaultFilters.status,
    selectedSubmitter: filters.selectedSubmitter || defaultFilters.selectedSubmitter,
    selectedUser: filters.selectedUser || defaultFilters.selectedUser,
    approvedProvider: filters.approvedProvider || defaultFilters.approvedProvider,
  };
};

export const saveFiltersToStorage = (filters: IFiltersLocalStorage) => {
  localStorage.setItem('saved-filters', JSON.stringify(filters));
};

export const getFiltersFromStorage = (): IFiltersLocalStorage => {
  const savedFilters: string | null = localStorage.getItem('saved-filters');

  if (savedFilters) {
    const savedFiltersJson: IFiltersLocalStorage = JSON.parse(savedFilters);

    return normalizeFilters(savedFiltersJson);
  }

  saveFiltersToStorage(defaultFilters);

  return defaultFilters;
};
