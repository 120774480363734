import axios from 'axios';
import { WithAbortFn } from '../../@types/external-api';
import { apiWrapper } from '../../config/api-wrapper';
import { IssueHightlight, ReviewIssue, TranscriptVersion } from './submission-types';
import { SearchSubmissionsParams, SubmissionDashboardDTO } from '../../interface/submission-dashboard-dto';
import * as utils from '../../screens/submission/utils';
import { SubmissionDetailsDTO } from '../../interface/submission-details-dto';
import { SubmissionIssueDTO } from '../../interface/submission-issue-dto';
import { ReviewIssueViolationRule } from '../../interface/review-issue-violation-rule';
import { SubmissionVersionsDTO } from '../../interface/submission-versions';
import { UpdateUserReviewStatusResponseDTO } from '../../interface/update-user-review-status-response-dto';
import { isSubmissionProcessing } from '../../helpers/submission-helper';
import { IOption } from '../../components/ui/multi-select-dropdown';

class SubmissionService {
  public searchSubmissions({
    sortCol,
    sortOrder,
    search,
    submitterName,
    affiliateId,
    active,
    status,
    assignedToName,
    approvedProviderName,
  }: SearchSubmissionsParams): WithAbortFn<Promise<Array<SubmissionDashboardDTO>>> {
    const source = axios.CancelToken.source();
    return {
      promise: apiWrapper.api
        .get('/submission', {
          params: {
            sort: sortCol,
            sortOrder,
            search: (search ?? '').length > 0 ? search : undefined,
            submitterName: submitterName && submitterName.length > 0 ? submitterName : undefined,
            affiliateId: affiliateId && affiliateId.length > 0 ? affiliateId : undefined,
            active: active && active.length > 0 ? active : undefined,
            status: status && status.length > 0 ? status : undefined,
            assignedToName: assignedToName && assignedToName.length > 0 ? assignedToName : undefined,
            approvedProviderName:
              approvedProviderName && approvedProviderName.length > 0 ? approvedProviderName : undefined,
          },
          paramsSerializer: params => {
            const searchParams = new URLSearchParams();

            Object.keys(params).forEach(key => {
              const value = params[key];
              if (value !== undefined && value !== null) {
                if (Array.isArray(value)) {
                  // Se for um array, adiciona os itens um por um, sem o formato de `[]`
                  value.forEach(val => {
                    searchParams.append(key, String(val));
                  });
                } else {
                  searchParams.append(key, String(value));
                }
              }
            });

            return searchParams.toString();
          },
          cancelToken: source.token,
        })
        .then((res: any) => {
          const submissions: SubmissionDashboardDTO[] = res.data;
          return submissions.map(submission => ({
            ...submission,
            modelDecision: this.mapModelDecision(submission),
          }));
        }),
      abort: source.cancel,
    };
  }

  public getSubmission(applyCode: number): WithAbortFn<Promise<SubmissionVersionsDTO>> {
    const source = axios.CancelToken.source();
    return {
      promise: apiWrapper.api
        .get(`/submission/${applyCode}`, {
          cancelToken: source.token,
        })
        .then(res => res.data)
        .then((pipelineOutputDetails: SubmissionVersionsDTO): SubmissionVersionsDTO => {
          const updatedSubmissions = pipelineOutputDetails.submissions.map((submission: SubmissionDetailsDTO) => ({
            ...submission,
            transcriptVersions: this.mapTranscriptVersions(submission.transcriptVersions),
            mediaType: submission.mediaType.charAt(0).toUpperCase() + submission.mediaType.toLowerCase().slice(1),
          }));
          const updatedSubmissionReviews: SubmissionIssueDTO[][] = this.mapSubmissionsReviews(
            pipelineOutputDetails.submissionsReviews,
            pipelineOutputDetails.submissions,
          );

          return {
            ...pipelineOutputDetails,
            submissions: updatedSubmissions,
            submissionsReviews: updatedSubmissionReviews,
          } as SubmissionVersionsDTO;
        }),
      abort: source.cancel,
    };
  }

  public createSubmissionIssue(
    reviewIssue: ReviewIssue,
    submissionId: string,
    reviewIssueViolationRule: ReviewIssueViolationRule[],
  ): WithAbortFn<Promise<SubmissionIssueDTO>> {
    const source = axios.CancelToken.source();
    const updatedReviewIssue = { ...reviewIssue, issues: JSON.stringify(reviewIssue.issues) };
    const data = { reviewIssue: updatedReviewIssue, reviewIssueViolationRule };

    return {
      promise: apiWrapper.api
        .put(`/submission/submission-issue/create?submissionId=${submissionId}`, data)
        .then((res: any) => res.data)
        .then((submissionIssue: SubmissionIssueDTO) => {
          return {
            ...submissionIssue,
            reviewIssue: {
              ...submissionIssue.reviewIssue,
              issues: submissionIssue.reviewIssue.issues
                ? (JSON.parse(submissionIssue.reviewIssue.issues.toString()) as IssueHightlight[])
                : null,
            },
          } as SubmissionIssueDTO;
        }),
      abort: source.cancel,
    };
  }

  public updateSubmissionIssues(
    submissionId: number,
    submissionReviews: SubmissionIssueDTO[],
  ): WithAbortFn<Promise<SubmissionIssueDTO[]>> {
    const source = axios.CancelToken.source();

    const updatedSubmissionReviews = submissionReviews.map(submissionReview => {
      return {
        ...submissionReview,
        issues: JSON.stringify(submissionReview.reviewIssue.issues),
        reviewIssue: {
          ...submissionReview.reviewIssue,
          issues: JSON.stringify(submissionReview.reviewIssue.issues),
        },
      };
    });

    return {
      promise: apiWrapper.api
        .put(`/submission/submission-issues/update?submissionId=${submissionId}`, updatedSubmissionReviews)
        .then((res: any) => res.data)
        .then((submissionIssues: SubmissionIssueDTO[]) => {
          return submissionIssues.map((submissionIssue: SubmissionIssueDTO) => {
            return {
              ...submissionIssue,
              reviewIssue: {
                ...submissionIssue.reviewIssue,
                issues: JSON.parse(submissionIssue.reviewIssue.issues.toString()) as IssueHightlight[],
              },
            } as SubmissionIssueDTO;
          });
        }),
      abort: source.cancel,
    };
  }

  public deleteSubmissionIssues(submissionReviews: SubmissionIssueDTO[]): WithAbortFn<Promise<SubmissionIssueDTO[]>> {
    const source = axios.CancelToken.source();

    const removedSubmissionReviews = submissionReviews.map(submissionReview => {
      return {
        ...submissionReview,
        issues: JSON.stringify(submissionReview.reviewIssue.issues),
        reviewIssue: {
          ...submissionReview.reviewIssue,
          issues: JSON.stringify(submissionReview.reviewIssue.issues),
        },
      };
    });

    return {
      promise: apiWrapper.api.put(`/submission/submission-issues/delete`, removedSubmissionReviews).then((res: any) => {
        return res;
      }),
      abort: source.cancel,
    };
  }

  public updateSubmissionIssue(
    reviewIssue: ReviewIssue,
    submissionId: string,
    reviewIssueViolationRule: ReviewIssueViolationRule[],
  ): WithAbortFn<Promise<SubmissionIssueDTO>> {
    const source = axios.CancelToken.source();
    const updatedReviewIssue = { ...reviewIssue, issues: JSON.stringify(reviewIssue.issues) };
    const data = { reviewIssue: updatedReviewIssue, reviewIssueViolationRule };

    return {
      promise: apiWrapper.api
        .put(`/submission/submission-issue/update?submissionId=${submissionId}`, data)
        .then((res: any) => res.data)
        .then((submissionIssue: SubmissionIssueDTO) => {
          return {
            ...submissionIssue,
            reviewIssue: {
              ...submissionIssue.reviewIssue,
              issues: JSON.parse(submissionIssue.reviewIssue.issues.toString()) as IssueHightlight[],
            },
          } as SubmissionIssueDTO;
        }),
      abort: source.cancel,
    };
  }

  public updateUserReviewStatus(reviewIssueViolationRuleId: number, userReviewStatus: boolean) {
    const source = axios.CancelToken.source();
    const data = { userReviewStatus };

    return {
      promise: apiWrapper.api
        .put(`/submission/submission-issue/user-review?reviewIssueViolationRuleId=${reviewIssueViolationRuleId}`, data)
        .then((res: any) => res.data)
        .then(
          (updateUserReviewStatusResponseDTO: UpdateUserReviewStatusResponseDTO) => updateUserReviewStatusResponseDTO,
        ),
      abort: source.cancel,
    };
  }

  public createTranscriptVersion(submissionId: number, data: TranscriptVersion) {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.post(`/features/transcript/${submissionId}`, data).then((res: any) => res.data),
      abort: source.cancel,
    };
  }

  public getTranscriptVersion(submissionId: number, versionNumber: number) {
    const source = axios.CancelToken.source();

    return {
      promise: apiWrapper.api.get(`/features/versions/${submissionId}/${versionNumber}`).then((res: any) => res.data),
      abort: source.cancel,
    };
  }

  public updateSubmissionDetails(submissionId: number, assignedToId: string, workflowStatusId: string) {
    const source = axios.CancelToken.source();

    const data = { assignedToId: parseInt(assignedToId), workflowStatusId };

    return {
      promise: apiWrapper.api.put(`/submission/update-details/${submissionId}`, data).then((res: any) => res.data),
      abort: source.cancel,
    };
  }

  private mapTranscriptVersions(transcriptVersions: Array<IOption>): Array<IOption> {
    return transcriptVersions.map((v: any) => ({
      value: v.versionNumber,
      label: utils.formatDate(v.createdAt),
    }));
  }

  private mapSubmissionsReviews(
    submissionsReviews: SubmissionIssueDTO[][],
    submissions: SubmissionDetailsDTO[],
  ): SubmissionIssueDTO[][] {
    const updatedSubmissionReviews: SubmissionIssueDTO[][] = [...submissionsReviews];

    submissionsReviews.forEach((submissionsReviewList: SubmissionIssueDTO[], submissionIndex: number) => {
      submissionsReviewList.forEach((submissionReview: SubmissionIssueDTO, submissionReviewIndex: number) => {
        let segments = submissions[submissionIndex].segments;

        if (segments !== null) {
          const issues = submissionReview.reviewIssue.issues;
          const constTimeStart = submissionReview.reviewIssue.timeStart;
          const issueContent = submissionReview.reviewIssue.issueContent;

          if (typeof submissions[submissionIndex].segments === 'string') {
            // @ts-ignore
            segments = JSON.parse(submissions[submissionIndex].segments.toString());
          }

          const merge = utils.mergeSegmentsAndTranscript(segments || []);
          const timestampString = utils.stringTimestamp(merge.mergedSegments);

          updatedSubmissionReviews[submissionIndex][submissionReviewIndex] = {
            reviewIssueViolationRule: submissionReview.reviewIssueViolationRule,
            reviewIssue: {
              ...submissionReview.reviewIssue,
              issues: issues ? (JSON.parse(issues.toString()) as IssueHightlight[]) : [],
              timeStart:
                constTimeStart === null
                  ? utils.findIssueStartTime(timestampString, merge.transcript, issueContent)!.startTime
                  : constTimeStart,
            },
          };
        }
      });
    });

    return updatedSubmissionReviews;
  }

  private mapModelDecision(submission: SubmissionDashboardDTO): string {
    if (submission.modelDecision) {
      return submission.modelDecision;
    }
    if (isSubmissionProcessing(submission)) {
      return 'Processing';
    }
    return 'Not available';
  }
}

const submissionService = new SubmissionService();
export default submissionService;
