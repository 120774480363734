import { Autocomplete, AutocompleteRenderOptionState, CircularProgress, TextField } from '@mui/material';
import debounce from 'lodash.debounce';
import { HTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Item } from '../dropdown-btn';
import './index.scss';

type Props = {
  id: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  items?: Item[];
  selectedValue?: Item[];
  styleType?: 'default' | 'filter';
  required?: boolean;
  search: (search: string) => Promise<Item[]>;
  selectValue: (selected: Item[]) => void;
  onOpenStateChange?: (open: boolean) => void;
};

export default function AsyncAutocompleteComponent({
  id,
  styleType = 'default',
  label,
  placeholder,
  disabled,
  items = [],
  selectedValue = [],
  required = false,
  search,
  selectValue,
  onOpenStateChange,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<Item[]>(items);
  const [loading, setLoading] = useState<boolean>(false);
  const [isTagHidden, setIsTagHidden] = useState<boolean>(false);

  useEffect(() => {
    setOptions(items);
  }, [items]);

  const performSearch = useCallback(
    (value?: string) => {
      setLoading(true);
      return search(value ?? '').then(fetchedItems => {
        setOptions(fetchedItems);
        setLoading(false);
        return fetchedItems;
      });
    },
    [search],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleKeyStroke = useCallback(
    debounce((_, value: string) => {
      performSearch(value);
    }, 750),
    [performSearch],
  );

  const handleSelection = useCallback(
    (_: any, value: Item[]) => {
      selectValue(value);
      setIsTagHidden(false);
    },
    [selectValue],
  );

  const handleOpen = useCallback(() => {
    setOpen(true);
    if (onOpenStateChange) {
      onOpenStateChange(true);
    }
  }, [onOpenStateChange]);

  const handleClose = useCallback(() => {
    setOpen(false);
    if (onOpenStateChange) {
      onOpenStateChange(false);
    }
  }, [onOpenStateChange]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<{}>, value: string) => {
      handleKeyStroke(event, value);
      setIsTagHidden(value !== '');
    },
    [handleKeyStroke],
  );

  const handleFocus = useCallback(() => {
    setOpen(true);
    onOpenStateChange?.(true);
  }, [onOpenStateChange]);

  const memorizedOptions = useMemo(() => options, [options]);

  return (
    <Form.Group className={`async-autocomplete ${styleType}`} id={id}>
      {label && (
        <Form.Label htmlFor={id} className={`autocomplete-form-label`}>
          {label} {required && <sup className="required-indicator">*</sup>}
        </Form.Label>
      )}
      <Autocomplete
        id={id}
        value={selectedValue ?? []}
        multiple={true}
        disabled={disabled}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={option => option.label}
        options={memorizedOptions}
        loading={loading}
        onChange={handleSelection}
        onInputChange={handleInputChange}
        disableCloseOnSelect={true}
        limitTags={1}
        filterOptions={x => x}
        renderOption={(props: HTMLAttributes<HTMLLIElement>, option: Item, state: AutocompleteRenderOptionState) => (
          <Row as="li" {...props} className="MuiAutocomplete-option">
            <Col md={option.secondLabel ? 7 : undefined}>{option.label}</Col>
            {option.secondLabel && (
              <Col md={1} className="second-label">
                <span>{option.secondLabel}</span>
              </Col>
            )}
          </Row>
        )}
        renderInput={params => (
          <TextField
            {...params}
            placeholder={selectedValue.length === 0 ? placeholder : ''}
            required={required}
            onFocus={handleFocus}
            InputProps={{
              ...params.InputProps,
              startAdornment: selectedValue.length > 0 && (
                <span className={`tag-container ${isTagHidden ? 'hidden-tag-container' : ''}`}>
                  <span className="truncate-text">{selectedValue[0].label}</span>
                  {selectedValue.length > 1 && <span className="tag-number">+{selectedValue.length - 1}</span>}
                </span>
              ),
              endAdornment: (
                <>
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
        renderTags={(value, getTagProps) => {
          if (value.length > 1) {
            return null;
          }
          return value.map((option, index) => (
            <span {...getTagProps({ index })} className="tag-item">
              {option.label}
            </span>
          ));
        }}
      />
    </Form.Group>
  );
}
